/********************************  HEADER  ***************************************/
.bg-header {
  background-color: orange;
}
.text {
  font-size: 24px;
}

/******************************   SEATS  *****************************************/
.box {
  padding: 5px 0 5px 0;
  border: 1px solid black;
  background-color: white;
  display: block;
  border-radius: 10px;
  margin:5px;
}
.bg {
  background-color: green;
}
img {
  width: 5vh;
  height: 5vh;
}
.center {
  display: block;
  margin-left: 3vh;
  margin-right: auto;
}
.p {
  margin-left: 25px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .text {
    font-size: 20px;
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .p {
    margin-left: 15px;
    display: block;
  }

  .center {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  .box {
    padding: 0;
    border: 1px solid black;
    background-color: white;
    display: flex;
  }

  .text {
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  .no-space {
    padding-left: 0;
  }
  .chkbox{
    margin-bottom:5px
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .p {
    display: block;
    margin-left: 0;
  }

  .center {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  .box {
    padding: 0;
    border: 1px solid black;
    background-color: white;
    display: block;
  }

  img {
    width: 8vh;
    height: 8vh;
  }

  .text {
    font-size: 12px;
    padding: 0;
    margin: 0;
  }
  .no-space {
    padding-left: 0;
  }

  .bg {
    background-color: green;
    padding: 0 6px 0 6px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .p {
    display: block;
    margin-left: 0;
  }

  .center {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  .box {
    padding: 0;
    border: 1px solid black;
    background-color: white;
    display: block;
  }

  img {
    width: 6vh;
    height: 6vh;
  }

  .text {
    font-size: 8px;
    padding: 5px 0 5px 0;
    margin: 0;
  }
  .no-space {
    padding-left: 0;
  }

  .bg {
    background-color: green;
    padding: 0 4px 0 4px;
  }

  .empty {
    font-size: 14px;
  }

  .entrance {
    font-size: 16px;
  }
}

@media only screen and (max-width: 320px) {
  .p {
    display: block;
    margin-left: 0;
  }

  .center {
    display: block;
    margin-left: 0;
    margin-right: auto;
  }
  .box {
    padding: 0;
    border: 1px solid black;
    background-color: white;
    display: block;
  }

  img {
    width: 6vh;
    height: 6vh;
  }
  .bg {
    background-color: green;
    padding: 0 2px 0 2px;
  }

  .text {
    font-size: 8px;
    padding: 5px 0 5px 0;
    margin: 0;
  }
  .no-space {
    padding-left: 0;
  }
  .entrance {
    font-size: 16px;
  }

  .empty {
    font-size: 14px;
  }
}
/************************* berth  ***************************************/

.bg-berth {
  background-color: #93c572;
}
.box-berth {
  justify-content: center;
}

/**********************************************************************************/

.empty-box {
  height: 8vh;
  background-color: orange;
}

/*******************************  PAGE 2    **************************************/

.col-border {
  border: 1px solid black;
  padding: 2vh;
}

.row-border-top {
  border-top: 2px solid black;
}

.row-border-bottom {
  border-bottom: 2px solid black;
}

.booked {
  filter: blur(2px);
  cursor: context-menu;
}

/* /////////////////////////////// */

.irow,
.e2row {
  width: 100%;
  display: grid;
  grid-template-columns: 230px 230px 230px 230px;
  justify-content: space-between;
  margin-top: 5px;
}
.prow{
  width: 100%;
  display: grid;
  grid-template-columns: 230px 230px 230px 230px;
  margin-top: 5px;
}
.oprow{
  width: 100%;
  display: grid;
  grid-template-columns: 190px 190px  ;
  margin-top: 5px;
}
.prow .right {
  margin-left: 400px;
}
.oprow .right{
  margin-left: 350px;
}
.rowBusiness {
  width: 100%;
  display: grid;
  grid-template-columns: 300px 300px 300px;
  justify-content: space-between;
  margin-top: 5px;
}
.acsleeper{
    margin-left: 230px;
}
.irow .box,
.rowBusiness .box,
.prow .box,
.e2row .box,.oprow .box {
  background-color: rgb(233, 233, 233);
}

.irow .col-2,
.rowBusiness .col-2,
.prow .col-2,
.e2row .col-2 ,.oprow .col-2{
  width: 60%;
  /* background-color: #4d9b19; */
}
.irow .col-4,
.rowBusiness .col-4,
.prow .col-4,
.e2row .col-4 ,.oprow .col-4{
  /* background-color: red; */
  width: 80%;
}
.irow .row,
.rowBusiness .row,
.prow .row,
.e2row .row,.oprow .row {
  width: 100%;
  margin-left: 5px;
  height: 90px;
}
.right {
  margin-left: 220px;
  display: flex;
  width: 80%;
}
.e2row .right {
  margin-left: 80px;
  display: flex;
  width: 75%;
}
.rowBusiness .right {
  margin-left: 330px;
  display: flex;
  width: 75%;
}
.left {
  display: flex;
  width: 80%;
}
.h5c {
  margin-left: 1px;
}
.cabin {
  margin-left: 70px;
}
.button {
  outline: none;
  border: 2px solid rgb(76, 190, 23);
  padding: 2px 4px;
  color: rgb(8, 8, 8);
  border-radius: 10px;
  margin-left: 2rem;
}
.trow {
  width: 100%;
  display: grid;
  grid-template-columns: 55.5% 37%;
  padding: 5px;
}
.trow button {
  padding: 6px 7px;
  border-radius: 20px;
  border: 2px solid green;
}
.trow :nth-child(2) {
  margin-left: 80px;
  width: 100%;
}
.e2trow {
  width: 100%;
  display: grid;
  margin: 10px;
}

.e2trow .right {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 50%;
  position: relative;
  left: 30%;
}

@media (min-width: 1000px) and (max-width: 1250px) {
  .right {
    margin-left: 60px;
  }
  .rowBusiness .right {
    margin-left: 70px;
  }
  .prow {
    grid-template-columns: 190px 190px 190px 190px;
    grid-gap: 50px;
  }
  .prow .right {
    margin-left: 240px;
  }
  .e2row .left {
    background-color: #93c572;
  }
  .e2row .right {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(4, 140px);
    width: 100%;
  }
}
@media (min-width: 1450px) and (max-width: 1980px) {
  .irow {
    grid-template-columns: 300px 300px 300px 300px;
  }
  .e2row {
    grid-template-columns: 320px 320px 320px 320px;
  }
  .e2row img {
    width: 100px;
    height: 100px;
  }
  .prow {
    width: 100%;
    display: grid;
    grid-template-columns: 280px 280px 280px 280px;
    margin-top: 5px;
  }
  .prow img {
    width: 100px;
    height: 100px;
    position: relative;
    right: 40px;
  }
  .prow .right {
    margin-left: 780px;
  }

  .rowBusiness {
    grid-template-columns: 360px 360px 360px;
  }
  .irow .box,
  .rowBusiness .box,
  .prow .box,
  .e2row .box {
    background-color: rgb(233, 233, 233);
  }

  .irow .col-2,
  .rowBusiness .col-2,
  .prow .col-2,
  .e2row .col-2 {
    width: 70%;
    /* background-color: #4d9b19; */
  }
  .irow .col-4,
  .rowBusiness .col-4,
  .prow .col-4,
  .e2row .col-4 {
    /* background-color: red; */
    width: 100%;
  }
  .irow .row,
  .rowBusiness .row,
  .prow .row,
  .e2row .row {
    width: 100%;
    margin-left: 15px;
    height: 180px;
  }
  .rowBusiness .row {
    margin-left: 60px;
  }

  .irow img {
    width: 100px;
    height: 100px;
    position: relative;
    right: 30px;
  }
  .right {
    margin-left: 380px;
    display: flex;
    width: 80%;
  }
  .e2row .right {
    margin-left: 80px;
    display: flex;
    width: 75%;
  }
  .rowBusiness .right {
    margin-left: 670px;
    display: flex;
    width: 75%;
  }
  .rowBusiness img {
    width: 100px;
    height: 100px;
    position: relative;
    right: 50px;
  }
  .left {
    display: flex;
    width: 80%;
  }
  .trow {
    width: 100%;
    display: grid;
    grid-template-columns: 45.5% 30%;
    padding: 5px;
  }
  .trow :nth-child(2) {
    margin-left: 380px;
    width: 100%;
  }
}
@media (min-width: 670px) and (max-width: 980px) {
  .prow {
    grid-template-columns: 190px 190px 190px 190px;
    grid-gap: 50px;
  }
  .prow .right {
    margin-left: 250px;
  }
  .e2row .left {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 130px);
  }
  .e2row .right {
    position: relative;
    right: 100px;
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(4, 130px);
    width: 100%;
  }
}
@media (min-width: 500px) and (max-width: 650px) {
  .prow {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .prow .right {
    margin-left: 0px;
  }
  .prow .left {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .e2row .left {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 130px);
  }
  .e2row {
    margin-left: 30px;
  }
  .e2row .right {
    position: relative;
    right: 100px;
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(2, 130px);
    width: 100%;
  }
  .trow {
    width: 80%;
  }
}
@media (min-width: 400px) and (max-width: 900px) {
  .right {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .irow {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .rowBusiness .right {
    margin-left: 23px;
  }
  .rowBusiness {
    display: grid;
    grid-template-columns: 270px 270px 270px;
  }
}
@media (min-width: 300px) and (max-width: 500px) {
  .right {
    margin-left: 0px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .irow {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .rowBusiness .right {
    margin-left: 70px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .rowBusiness {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
