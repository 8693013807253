/********************************  HEADER  ***************************************/
.bg-header{
    background-color: orange;
}
.text{
    font-size: 24px;
}

/******************************   SEATS  *****************************************/
.box{
  padding: 10px 0 10px 0;
  border: 1px solid black;
  background-color: white;
  display: flex;
}
.bg{
    background-color: green;
}
img{
    width: 10vh;
    height: 10vh;
}
.center{
    display: block;
    margin-left: 3vh;
    margin-right: auto;
  
}
.p{
    margin-left: 25px;
}


 @media (min-width: 992px) and (max-width: 1199px){
        
.text{
    font-size: 20px;
    padding: 0;
    margin: 0;
}
}
@media (min-width: 768px) and (max-width: 991px){
    .p{
        margin-left: 15px;
        display: block;
       
    }
    
    .center{
        display: block;
        margin-left: 0;
        margin-right: auto;
    }
    .box{
        padding: 0;
        border: 1px solid black;
        background-color: white;
        display: flex;
      }
      
.text{
    font-size: 18px;
    padding: 0;
    margin: 0;
}
.no-space{
    padding-left: 0;
   }
}

@media (min-width: 480px) and (max-width: 767px){   
.p{
    display: block;
    margin-left: 0;
}

.center{
    display: block;
    margin-left: 0;
    margin-right: auto;
}
.box{
    padding: 0;
    border: 1px solid black;
    background-color: white;
    display: block;
  }
    
img{
    width: 8vh;
    height: 8vh;
}
     
.text{
    font-size: 12px;
    padding: 0;
    margin: 0;
}
.no-space{
    padding-left: 0;
   }
   
.bg{
    background-color: green;
    padding: 0 6px 0 6px;
}
}
@media (min-width: 321px) and (max-width: 479px){
    .p{
        display: block;
        margin-left: 0;
    }
    
    .center{
        display: block;
        margin-left: 0;
        margin-right: auto;
    }
    .box{
        padding: 0;
        border: 1px solid black;
        background-color: white;
        display: block;
      }
        
    img{
        width: 6vh;
        height: 6vh;
    }
         
.text{
    font-size: 8px;
    padding: 5px 0 5px 0;
    margin: 0;
}
.no-space{
 padding-left: 0;
}

.bg{
    background-color: green;
    padding: 0 4px 0 4px;
}
   
.empty{
    font-size: 14px;
}

.entrance{
    font-size: 16px;
}
}


@media only screen and (max-width: 320px){
    .p{
        display: block;
        margin-left: 0;
    }
    
    .center{
        display: block;
        margin-left: 0;
        margin-right: auto;
    }
    .box{
        padding: 0;
        border: 1px solid black;
        background-color: white;
        display: block;
      }
        
    img{
        width: 6vh;
        height: 6vh;
    }
    .bg{
        background-color: green;
        padding: 0 2px 0 2px;
    }
            
.text{
    font-size: 8px;
    padding: 5px 0 5px 0;
    margin: 0;
}
.no-space{
 padding-left: 0;
}
.entrance{
    font-size: 16px;
}
    
.empty{
    font-size: 14px;
}
}
/************************* berth  ***************************************/

.bg-berth{
    background-color: #93C572;
}
.box-berth{
    justify-content: center;
}


/**********************************************************************************/

.empty-box{
    height: 8vh;
    background-color: orange;
}

/*******************************  PAGE 2    **************************************/

.col-border{
    border: 1px solid black;
    padding: 2vh;
}

.row-border-top{
    border-top: 2px solid black;
}


.row-border-bottom{
    border-bottom: 2px solid black;
}

.booked {
    filter:blur(2px);
    cursor: context-menu;
}